import { HTMLAttributes, useMemo } from 'react';

import { getRandomValue } from 'utils/getRandomValue';
import cssVar from 'utils/cssVar';

import { ParticlesOptions, Particle, Props } from './Particles.types';
import classes from './Particles.module.scss';

const defaultOptions: Omit<ParticlesOptions, 'color'> = {
  size: { min: 10, max: 50 },
  twinkle: { min: 0, max: 0.5 },
  blur: { min: 0, max: 10 },
  opacity: { min: 0.2, max: 0.8 },
  offset: { x: 10, y: 10 },
  amplitude: { x: 300, y: 300 },
  lifeTime: { min: 15000, max: 25000 },
};

const Particles = ({ count = 5, options = defaultOptions }: Props): JSX.Element => {
  const { color, size, twinkle, blur, opacity, offset, amplitude, lifeTime }: ParticlesOptions = {
    ...defaultOptions,
    ...options,
    color: options.color || cssVar('activeColor'),
  };

  const particles: Particle[] = useMemo(
    () =>
      [...new Array(count)].map((_, i) => ({
        id: `particle-${i}`,
        position: {
          x: getRandomValue(offset.x, 100 - offset.x),
          y: getRandomValue(offset.y, 100 - offset.y),
        },
        vector: {
          x: getRandomValue(-amplitude.x, amplitude.x),
          y: getRandomValue(-amplitude.y, amplitude.y),
        },
        color: typeof color === 'function' ? color(i) : color,
        size: getRandomValue(size.min, size.max),
        twinkle: 1 - getRandomValue(twinkle.min, twinkle.max),
        blur: getRandomValue(blur.min, blur.max),
        opacity: getRandomValue(opacity.min, opacity.max),
        lifeTime: Math.floor(getRandomValue(lifeTime.min, lifeTime.max)),
      })),
    [
      count,
      color,
      size.min,
      size.max,
      twinkle.min,
      twinkle.max,
      blur.min,
      blur.max,
      opacity.min,
      opacity.max,
      offset.x,
      offset.y,
      amplitude.x,
      amplitude.y,
      lifeTime.min,
      lifeTime.max,
    ]
  );

  return (
    <div className={classes.wrapper}>
      {particles.map(
        ({
          id,
          position,
          vector,
          color: particleColor,
          size: particleSize,
          twinkle: particleTwinkle,
          blur: particleBlur,
          opacity: particleOpacity,
          lifeTime: particleLifeTime,
        }) => (
          <div
            key={id}
            className={classes.dot}
            style={
              {
                '--position-x': `${position.x}%`,
                '--position-y': `${position.y}%`,
                '--vector-x': `${vector.x}px`,
                '--vector-y': `${vector.y}px`,
                '--color': particleColor,
                '--size': `${particleSize}px`,
                '--twinkle': particleTwinkle,
                '--blur': `${particleBlur}px`,
                '--opacity': particleOpacity,
                '--life-time': `${particleLifeTime}ms`,
              } as HTMLAttributes<HTMLDivElement>['style']
            }
          />
        )
      )}
    </div>
  );
};

export default Particles;
