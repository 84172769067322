import flatPalette from 'styles/exports/palette.module.scss';
import gradient from 'styles/exports/gradients.module.scss';
import unflattenObject from 'utils/unflattenObject';

type Palette = {
  black: string;
  white: string;
  lightGray: string;
  darkGray: string;
  error: string;
  errorLight: string;
  seaSerpent: string;
  metallicSeaweed: string;
  policeBlue: string;
  natoBlue: string;
  japanesseIndigo: string;
  blueWhale: string;
  gunMetal: string;
  gold: string;
  darkGold: string;
  transparentBlack: string;
  transparentLightBlack: string;
  gray: Record<100 | 95 | 90 | 80 | 75 | 70 | 65 | 60 | 55 | 40 | 30 | 20, string>;
  blue: Record<100 | 95 | 80 | 70 | 65 | 60 | 52 | 50 | 35 | 25 | 20 | 15 | 10 | 5, string>;
  green: Record<100 | 60 | 50 | 40 | 30 | 10, string>;
};

const palette = unflattenObject<Palette>(flatPalette);

export default palette;
export { gradient };
