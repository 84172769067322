import keysOf from 'utils/keysOf';

const unflattenObject = <T extends Record<string, unknown>>(flatObject: Record<string, unknown>) =>
  keysOf(flatObject).reduce((acc, curr) => {
    const [key, nestedKey] = `${curr}`.split('-');
    const value = acc[key];
    const mappedValue = typeof value === 'object' ? value : {};

    return {
      ...acc,
      [key]: nestedKey ? { ...mappedValue, [nestedKey]: flatObject[curr] } : flatObject[curr],
    };
  }, {} as T);

export default unflattenObject;
