import { defineMessages } from 'utils/intl';

export default defineMessages({
  laptops: 'Laptopy',
  computers: 'Komputery',
  monitors: 'Monitory',
  mobileWorkstations: 'Mobilne stacje robocze',
  workstations: 'Stacjonarne stacje robocze',
  businessStandard: 'Biznesowy Standard',
  laptopsDescription:
    'Laptopy Dell, HP, Lenovo w biznesowym standardzie świetnie sprawdzą się jako sprzęt do codziennej pracy biurowej, który można zabrać ze sobą w służbową podróż. To po prostu poprawna wydajność w optymalnej cenie.',
  laptopsSmallDescription: 'Intel® Core™ i3 lub i5 / 8GB RAM / 256GB SSD / FullHD / Grafika zintegrowana',
  computersDescription:
    'Komputery Dell, HP, Lenovo do codziennej pracy w korzystnym budżecie – wydajność sprawdzi się w typowych zadaniach biurowych, korzystna cena pozwoli na skompletowanie akcesoriów jak monitor i klawiatura.',
  computersSmallDescription: 'Intel® Core™ i3 lub i5 / 8GB RAM / 256GB SSD / FullHD / Grafika zintegrowana',
  monitorsDescription:
    'Podstawowe zadania biurowe, regulacja pozwalająca na ergonomię pracy i dobra rozdzielczość w 24″ – tym charakteryzują się optymalne cenowo monitory Dell, HP, Lenovo serii biznesowy standard.',
  laptopsPlusDescription: 'Intel® Core™ i5 lub i7 / 16GB RAM / 512GB SSD / FullHD / Grafika zintegrowana',
  computersPlusDescription: 'Intel® Core™ i5 lub i7 / 16GB RAM / 512GB SSD / FullHD / Grafika zintegrowana',
  workstationsDescription: 'Wydajne stacje robocze Dell Precision, HP Z, Lenovo ThinkStation, Fujitsu Celsius ',
  mobileWorkstationsDescription: 'Wydajne stacje robocze Dell Precision, HP ZBook, Lenovo ThinkPad P, Fujitsu Celsius ',
  businessStandardPlus: 'Biznesowy Standard PLUS',
  processor: 'Procesor Intel® Core™',
  ramMemory: 'Pamięć RAM',
  ssdDisk: 'Dysk SSD',
  screenResolution: 'Rozdzielczość ekranu',
  screen: 'Ekran',
  integratedGraphics: 'Grafika zintegrowana',
  screenDiagonal: 'Przekątna ekranu',
  rotatedScreen: 'Obrotowy ekran',
  heightRegulation: 'Regulacja wysokości',
  wideScreen: 'Szerokie kąty widzenia',
  seeProducts: 'Zobacz produkty',
  or: ' lub ',
});
