import keysOf from 'utils/keysOf';

type SlugHref = {
  pathname: string;
  query: Record<string, string | number>;
};

const resolveSlugHref = (slug: SlugHref): string => {
  const keys = keysOf(slug.query);
  const href = keys.reduce((acc, key) => acc.replace(`[${key}]`, `${slug.query[key]}`), slug.pathname);

  return href;
};

export default resolveSlugHref;
